import { useMemo } from "react";

import { useTranslation } from "next-i18next";

import { ManagedMRTCategories, ManagedMaterialReactTableCategory } from "@components";
import { loadTranslations } from "@lib";
import { SourcingCampaign } from "@typings";

import { CAMPAIGNS_FILTERS, CAMPAIGNS_FILTERS_TYPE, sourcingCampaignStatusNormalizer } from "./sourcing-campaigns-page";

export const SourcingCampaignsHeader = ({
  campaignsTab,
  campaigns,
  onCategoryChange,
}: {
  campaignsTab: CAMPAIGNS_FILTERS_TYPE;
  campaigns: Record<string, SourcingCampaign>;
  onCategoryChange: (newStatusFilter: CAMPAIGNS_FILTERS_TYPE) => void;
}) => {
  const { t } = useTranslation(["sourcing-campaigns"]);
  loadTranslations("sourcing-campaigns");

  const categoryCount = useMemo(() => {
    const counts: Record<CAMPAIGNS_FILTERS_TYPE, number> = {
      all: 0,
      ongoing: 0,
      finished: 0,
    };

    Object.values(campaigns).forEach((campaign) => {
      counts.all += 1;

      if (sourcingCampaignStatusNormalizer(campaign.status) === "finished") {
        counts.finished += 1;
      } else {
        counts.ongoing += 1;
      }
    });
    return counts;
  }, [campaigns]);

  const categories = useMemo<ManagedMaterialReactTableCategory[]>(
    () =>
      CAMPAIGNS_FILTERS.map((category) => ({
        id: "campaign_filter_card_" + category,
        value: category,
        selected: category === campaignsTab,
        displayValue: t(`sourcing-campaigns:tabs.${category}`),
        count: categoryCount[category],
      })),
    [campaignsTab, categoryCount, t],
  );

  return <ManagedMRTCategories categories={categories} onCategoryChange={onCategoryChange} />;
};
