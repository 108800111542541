import { FC } from "react";

import { useTranslation } from "next-i18next";

import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { AuthProxyAPI } from "@api";
import { InterviewCard } from "@components";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { AriaProps, AuthProxyUser, Interview } from "@typings";

import { CommentListSubInfo, TimelinePerson } from "./style";

interface InterviewDetailProps extends AriaProps {
  interview: Interview;
}

export const InterviewDetail: FC<InterviewDetailProps> = ({ interview, role }) => {
  const { data: user, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, interview.recruiter_user_id],
    queryFn: () => AuthProxyAPI.getUser(context.active(), interview.recruiter_user_id),
  });

  return <InterviewDetailUI interview={interview} role={role} user={user} isLoading={isLoading} />;
};

interface InterviewUIDetailProps extends AriaProps {
  interview: Interview;
  user: AuthProxyUser | undefined;
  isLoading: boolean;
}

export const InterviewDetailUI: FC<InterviewUIDetailProps> = ({ interview, role, user, isLoading }) => {
  const { t } = useTranslation(["interview-details", "dates"]);
  loadTranslations("interview-details");
  loadTranslations("dates");

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        <TimelinePerson userName={user ? `${user.first_name} ${user.last_name}` : undefined} userEmail={user?.email} />
        <TimelineConnector sx={(theme) => ({ color: theme.palette.grey[300] })} />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px !important" }}>
        {user && !isLoading && (
          <Box>
            <Box
              sx={{
                color: TEXT_COLOR.mainInfo,
                fontSize: "14px",
              }}
            >
              <b>
                {user.first_name} {user.last_name}
              </b>{" "}
              {t("created_interview")}
            </Box>
            <Box>
              <InterviewCard interview={interview} />
            </Box>
            <CommentListSubInfo created_at={interview.created_at} />
          </Box>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
