import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";

import { context } from "@opentelemetry/api";

import { useQuery } from "@tanstack/react-query";

import { JobsApi } from "@api";
import { ManagedColumnFilter, ManagedMaterialReactTable, Spinner, useManagedMaterialReactTable } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";

import { useUserGroup } from "@hooks";

import { RowActions } from "./cells";
import { INITIAL_COLUMNS_VISIBILITY } from "./constants";
import { useOnRowClick } from "./hooks";
import { useJobsCategories } from "./hooks/categories";
import { useJobsGroupActions } from "./hooks/group-actions";
import { useJobsColumns } from "./jobs-columns";

const onBeforeSaveColumnFilters = (value: ManagedColumnFilter[] | undefined, destination: "url" | "localStorage") => {
  if (value == null) {
    return [];
  }

  if (destination === "url") {
    return value.filter((filter) => filter.id !== "id");
  }

  return value;
};

export const JobsList = () => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  const organization = useUserGroup();

  const columns = useJobsColumns();

  const [filterableColumns] = useState<string[]>(["name", "city"]);

  const onRowClick = useOnRowClick();

  const translateColumn = useCallback((columnID: string) => t(`columns.${columnID}.shortName`), [t]);

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS_LIST],
    queryFn: () => JobsApi.list(context.active(), {}),
  });

  const table = useManagedMaterialReactTable({
    seizaFilters: {
      cacheKey: "jobs_list",
      initialColumnsVisibility: INITIAL_COLUMNS_VISIBILITY,
      onBeforeSaveColumnFilters,
    },
    columns,
    data: jobsQuery.data?.jobs || [],
    state: { isLoading: jobsQuery.isLoading },
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      columnPinning: { right: ["mrt-row-actions"] },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ cell, row }) => ({
        onClick: () => {
          if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) onRowClick(row.original.id);
        },
      }),
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    muiTableContainerProps: { id: "jobs_list" },
    muiTableBodyRowProps: ({ row }) => ({
      id: `job_item_${row.original.id}`,
      className: "job_item",
    }),
    renderRowActions: ({ table, row }) => (
      <RowActions row={row} disabled={table.getSelectedRowModel().rows.length > 0} />
    ),
  });

  const { groupActions, groupActionsModals } = useJobsGroupActions(table);

  const { categories, onCategoryChange } = useJobsCategories(table);

  const { setColumnOrder } = table;

  // Ensure that the columns are in the correct order.
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  if (!organization) {
    return <Spinner style={{ position: "relative" }} />;
  }

  return (
    <>
      <ManagedMaterialReactTable
        table={table}
        title={t("title")}
        categories={categories}
        onCategoryChange={onCategoryChange}
        filterableColumns={filterableColumns}
        translateColumn={translateColumn}
        globalFilter
        showActiveFilters
        groupedActions={groupActions}
      />
      {groupActionsModals}
    </>
  );
};
