import { FC, useMemo, useState } from "react";

import { MRT_Row } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Box, Chip, Stack, Theme, Tooltip } from "@mui/material";
import { SxProps } from "@mui/system";

import { BORDER_RADIUS } from "@constants";
import { loadTranslations } from "@lib";
import { JobStatus, ListJobItem } from "@typings";

const STATUS_CHIP_SX: SxProps<Theme> = {
  borderRadius: BORDER_RADIUS[1],
  padding: (theme) => `${theme.spacings[4]} 0`,
  fontSize: "0.8rem",
  width: "fit-content",
  height: "fit-content",
};

const StatusChip: FC<{ row: MRT_Row<ListJobItem> }> = ({ row }) => {
  const { t } = useTranslation(["jobs-list"]);
  loadTranslations("jobs-list");

  if (row.original.status === JobStatus.ACTIVE) {
    return (
      <Chip
        label={t("columns.name.statuses.active")}
        sx={{
          ...STATUS_CHIP_SX,
          backgroundColor: (theme) => theme.palette.success.light,
          color: (theme) => theme.palette.color.BASE[800],
        }}
      />
    );
  }

  return (
    <Chip
      label={t("columns.name.statuses.inactive")}
      sx={{
        ...STATUS_CHIP_SX,
        backgroundColor: (theme) => theme.palette.color.BASE[200],
        color: (theme) => theme.palette.color.BASE[800],
      }}
    />
  );
};

export const NameCell: FC<{ row: MRT_Row<ListJobItem> }> = ({ row }) => {
  const [titleRef, setTitleRef] = useState<HTMLDivElement | null>(null);

  const isTooltipVisible = useMemo(() => titleRef?.innerText !== row.original.title, [titleRef, row.original.title]);

  const content = (
    <Stack flexDirection="column" gap={(theme) => theme.spacings[4]}>
      <Box
        ref={setTitleRef}
        overflow="hidden"
        textOverflow="ellipsis"
        display="-webkit-box"
        fontSize="1rem"
        fontWeight={700}
        lineHeight="150%"
        color={(theme) => theme.palette.color.BASE[800]}
        sx={{
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {row.original.title}
      </Box>
      <StatusChip row={row} />
    </Stack>
  );

  return isTooltipVisible ? (
    <Tooltip title={row.original.title} placement="top-start">
      {content}
    </Tooltip>
  ) : (
    <>{content}</>
  );
};
